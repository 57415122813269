import * as React from 'react';
import { Container, Grid, Box, Link, Typography, AppBar, useMediaQuery, useTheme } from '@mui/material';
import ProfessionalImage from '../media/professional.jpg';
import Verification from '../components/Verification';

const Professional: React.FC = () => {
  const theme = useTheme();
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      <Container style={{ maxWidth: 760 }}>
        <Typography align={'center'} variant={'h4'} sx={{ fontFamily: 'Lora, serif'}} padding={isMdOrLess ? 1 : 3}>
          Professional
        </Typography>
        <Typography align={'left'} variant={'body1'} padding={1}>
          Whether you are new to the field and working to obtain licensure or a seasoned clinician, I believe it is critical for mental health professionals to receive quality, meaningful and relationally focused supervision. Therefore, I am able to provide clinical supervision to those seeking their LCSW, LMHC, LMFT or LCAT. 
        </Typography>
        <Typography align={'center'} variant={'body1'} padding={1}>
          Please send me an email at <Link href={'mailto:jsaraceno.lcsw@gmail.com'}>jsaraceno.lcsw@gmail.com</Link> to further discuss details.
        </Typography>
        <Typography align={'center'} variant={'body2'} padding={1}>
          LCSW License # 093270
        </Typography>
        <Grid item container justifyContent={'center'}>
          <Grid item sm={12} md={6}> 
            <Typography align={'center'} variant={'body2'} padding={1}>
              <Link href={'http://www.op.nysed.gov/prof/sw/lcsw.htm'}>Licensed Master Clinical Social Workers (LCSW)</Link>
            </Typography>
            <Typography align={'center'} variant={'body2'} padding={1}>
              <Link href={'http://www.op.nysed.gov/prof/mhp/mhclic.htm'}>Licensed Mental Health Counselors (LMHC) </Link>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography align={'center'} variant={'body2'} padding={1}>
              <Link href={'http://www.op.nysed.gov/prof/mhp/mftlic.htm'}>Licensed Marriage and Family Therapists (LMFT) </Link>
            </Typography>
            <Typography align={'center'} variant={'body2'} padding={1}>
              <Link href={'http://www.op.nysed.gov/prof/mhp/catlic.htm'}>Licensed Creative Arts Therapists (LCAT)</Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container minWidth={300} justifyContent={'center'}>
          <Grid item width={'100%'} height={'100%'} justifyContent={'center'} alignContent={'stretch'}>
              <Box sx={{
                height: 360,
                width: '100%',
                backgroundImage: `url(${ProfessionalImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: -2,
                borderRadius: 4,
              }}/>
          </Grid>
          {/* Increase the network loading priority of the background image. */}
          <img
            style={{ display: 'none' }}
            src={ProfessionalImage}
            alt="increase priority"
          />
        </Grid>
        <Grid container minWidth={300} justifyContent={'center'}>
          <Grid item padding={2}>
            <Verification />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Professional;
