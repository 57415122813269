import * as React from 'react';
import { Grid, Typography, Link, useMediaQuery, useTheme, Card, Button } from '@mui/material';
import Headshot from '../media/js_headshot.jpeg';
import { Link as RouterLink } from 'react-router-dom';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
    createStyles({
      quote: {
        color: "white",
        textShadow: '1px 1px 3px black',
        maxWidth: 600,
      },
    }),
  );

const Info: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles();
    const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
    const isLgOrLess = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <Grid item container justifyContent={isLgOrLess ? 'center' : 'space-between'} width={'100%'} maxWidth={840}>
            {/* Name title */}
            <Grid item margin={1} marginBottom={2}>
                <Typography className={classes.quote} color={'white'} align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={isMdOrLess ? 'h4' : 'h3'}>JULIE SARACENO</Typography>
                <Typography className={classes.quote} color={'white'} align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={isMdOrLess ? 'h5' : 'h4'}>LCSW, RPT</Typography>
                <Typography className={classes.quote} color={'white'} letterSpacing={1.5} align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={'subtitle2'}>Licensed Clinical Social Worker</Typography>
                <Typography className={classes.quote} color={'white'} letterSpacing={1.5} align={'left'} fontWeight={500} sx={{ fontFamily: 'Lora, serif'}} variant={'subtitle2'}>Registered Play Therapist</Typography>
            </Grid>
            {/* Lets connect card */}
            <Card item component={Grid} container alignContent={'space-between'} elevation={3} minHeight={210} height={210} width={300} minWidth={300} padding={2} margin={1}>
                <Grid item container width={'100%'} justifyContent={'space-between'} flexDirection={'row'}>
                    <Grid item container width={'auto'} flexDirection={'column'} justifyContent={'space-between'} >
                        <Grid item width={'auto'}>
                            <Typography color={'#333333'} align={'left'} fontWeight={300} variant={'h5'}>Let's connect!</Typography>
                        </Grid>
                        <Grid item container width={'auto'}>
                            <PhonelinkRingIcon sx={{ color: '#779ba1'}} />
                            <Typography align={'left'} variant={'body2'} marginTop={'auto'} paddingLeft={'8px'}>
                                <Link target="_blank" underline={'hover'} color={'#779ba1'} href={'tel:+15853718370'}>(585) 371-8370</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item minWidth={100} minHeight={120}>
                        <Card elevation={2} 
                            sx={{
                                height: '100%',
                                minWidth: '100%',
                                backgroundImage: `url(${Headshot})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                zIndex: -2,
                                borderRadius: 20,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item width={'100%'}>
                    <Button variant={'text'} className={'contact-button'} fullWidth component={RouterLink} to={'/contact'}>
                        Send me a message
                    </Button>
                </Grid>
            </Card>
        </Grid>
    );
}

export default Info;
