import React from "react";
import { Provider } from "react-redux";
import store from '../store/index';
type Props = {
    children: JSX.Element,
};

const AppProvider: React.FC<Props> = ({ children }) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

export default AppProvider;