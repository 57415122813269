
export const enum ServiceEnum {
    infant=1,
    children=2,
    adolescent=3,
    adult=4,
};

export const enum ServiceID {
    infant='infant-section',
    children='children-section',
    adolescent='adolescent-section',
    adult='adult-section',
};

export const childParent = [
  'Transitioning into the caregiving role',
  'Lack of connection between parent and caregiver',
  'Caregiver stress',
  'Behavioral concerns',
  'Sleep disruptions',
  'Feeding struggles',
  'Separation Anxiety',
];

export const childParentApproaches = [
  'Infant Mental Health (Prenatal- age 3)',
  'Child-Parent Psychotherapy (CPP) (Prenatal- age 3)',
  'Dyadic Play Therapy (age 2 to 12)',
];

export const children = [
  'Tantrums',
  'Anxiety',
  'Poor social skills',
  'Defiance',
  'Hyperactivity',
  'Caregiver/Child attachment disruption',
  'Aggression',
  'Lying or stealing',
  'Sleeping difficulties',
  'Attention challenges',
  'Depression',
  'Power/control',
  'Bullying',
];

export const childrenApproaches = [
  'Child Centered Play Therapy',
  'Sand Tray Therapy',
  'Cognitive Behavioral Therapy',
  'Mindfullness Techniques',
  'Caregiver Partnering',
];

export const adolescents = [
  'Self-confidence',
  'Grief & loss',
  'Motivation',
  'Anxiety',
  'Depression',
  'Discovering self',
  'Peer difficulties',
  'Trauma',
  'Bullying',
];

export const adolescentApproaches = [
  'Psychotherapy',
  'Cognitive Behavioral Therapy',
  'Mindfulness',
  'Caregiver Partnering',
];

export const adults = [
  'Grief & loss',
  'Caregiving struggles',
  'Anxiety',
  'Stress management',
  'Depression',
  'Relational conflict/disruption',
  'Life transitions',
  'Trauma',
  'Self-identity',
  'Motivation',
];

export const adultApproaches = [
  'Psychotherapy',
  'Cognitive Behavioral Therapy',
  'Mindfulness',
];