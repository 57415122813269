import * as React from 'react';
import Info from '../components/Info';
import { Grid, Typography, useMediaQuery, useTheme, Divider } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Field from '../media/journey_field.jpg';
import Services from './Services';

const useStyles = makeStyles(() =>
  createStyles({
    quote: {
      color: "white !important",
      textShadow: '1px 1px 3px black',
      maxWidth: 600
    },
  }),
);

const Home: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const image = Field;
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container flexDirection={isMdOrLess ? 'row-reverse' : 'row'} justifyContent={'center'} alignContent={'flex-start'}>
      <Grid item minHeight={'300px'} maxHeight={'400px'} width={"100%"} xs={12}>
        <Grid container sx={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          //maskImage: 'linear-gradient(180deg, rgba(240,255,243,1) 70%, rgba(255,255,255,0) 100%)',
          zIndex: -2
        }}>
          <Grid item container>
            <Grid item container justifyContent={'right'} xs={12} >
              <Typography padding={'20px 40px 10px 40px'} align={'right'} variant={'h6'} fontWeight={300} className={classes.quote}>
                {'"It is a joy to be hidden, but a disaster not to be found."'}
              </Typography>
            </Grid>
            <Grid item container justifyContent={'right'} xs={12}>
              <Typography align={'right'} variant={'h6'} padding={'0px 40px'} width={'100%'} fontWeight={300} className={classes.quote}>
                -D.W. Winnicott
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justifyContent={'center'} padding={3} xs={12} maxWidth={800} marginTop={'auto'}>
            <Info />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent={'center'} xs={12}>
        <Grid item container padding={isSmOrLess ? '120px 24px 36px 24px' : '46px 24px 36px 24px'} justifyContent={'center'} style={{ maxWidth: 1200 }} xs={12} md={8}>
          <Typography align={'left'} variant={'h6'} fontWeight={400} padding={1}>
            Whether you are new to therapy or are looking for something new, the task of seeking out a therapist for yourself and/or your children can seem a bit daunting.
          </Typography>
          <Typography align={'left'} variant={'subtitle1'} fontWeight={300} padding={1}>
            As a Clinical Social Worker and Registered Play Therapist, I am here to partner with you on your journey to restoring balance and mental wellness to your life. 
          </Typography>
        </Grid>
        <Grid item justifyContent={'center'} padding={'0px 24px'} xs={12} maxWidth={800} marginTop={'auto'}>
          <Divider variant='fullWidth' sx={{ width: '100%' }}/>
        </Grid>
      </Grid>
      <Grid item padding={1} justifyContent={'center'} style={{ maxWidth: 1600 }} xs={12}>
        <Services/>
      </Grid>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={image}
        alt="increase priority"
      />
    </Grid>
  );
}

export default Home;
