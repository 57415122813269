import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';


export const createModeTheme = (mode: string) => {
  return createTheme({
    palette: {
      mode: mode as PaletteMode,
      text: {
        primary: '#333333',
        secondary: '#333333'
      },
      primary: {
        main: '#333333',
      },
      secondary: {
        main: '#333333'
      },
    },
  });
};
