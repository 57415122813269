import { combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import appReducer from '../util/Slice'


export const rootReducer = combineReducers({
    app: appReducer,
});

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;