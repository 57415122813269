import * as React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectAvailable } from '../util/Slice';

const useStyles = makeStyles(() =>
  createStyles({
    spwidgetWrap: {
        textAlign: 'center',
        textShadow: '0px 0px 3px #ffdbbe',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spwidgetButton: {
        width: '100%',
        maxWidth: '250px',
        display: 'inline-block',
        padding: '5px 15px',
        color: '#333333',
        background: '#f5d8c1',
        border: 0,
        lineHeight: "1.65",
        borderRadius: '4px',
        fontSize: '16px',
        fontWeight: 400,
        textDecoration: 'none',
        ':hover': {
            background: '#d15913',
        },
        ':active': {
            boxShadow: '0 1px 3px rgba(0, 0, 0, .15) inset',
        }
    },
    spwidgetButtonDisabled: {
    },
    unavailableText: {
        fontSize: 12,
        width: 250
    }
  })
);
const AppointmentButton: React.FC = () => {
    const classes = useStyles();
    const available = useSelector(selectAvailable);
    return (
        <div className={classes.spwidgetWrap}>
            {!available && <span className={classes.unavailableText}>I am currently unable to accept new clients</span>}
            <a href="https://julie-saraceno.clientsecure.me" className={`${classes.spwidgetButton} ${available ? '' : classes.spwidgetButtonDisabled}`}>
                Request Appointment
            </a>
        </div>
    );
}

export default AppointmentButton;
