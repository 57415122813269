export const acceptedInsurance = [
    'Blue Cross Blue Shield',
    'Excellus BCBS',
    'MVP',
    'Aetna*',
    'Cigna*',
    'Oscar*',
    'Oxford*',
    'United Healthcare*'
];

export const privatePay = [
    {
        text: '15 minute Initial Consultation Phone Call- No Charge',
        note: ''
    },
    {
        text: '50 minute Individual Sessions $120',
        note: '*Child sessions are 40 minutes with an additional 10 minutes for parent consultation'
    },
    {
        text: '50 minute Parent/Caregiver Consultation $120',
        note: "*Dedicated parent/caregiver time to discuss child’s treatment in detail and/or for parental support"
    },
    {
        text: '50 minute Family Sessions $120',
        note: '*May consist of a blend of individual and family time'
    }
];

