import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes, BrowserRouter as Router, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createModeTheme } from './theme';
import { Grid, Typography, Link, useTheme, BottomNavigation, Paper } from '@mui/material';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import Services from './pages/Services';
import Fees from './pages/Fees';
import Professional from './pages/Professional';
import Contact from './pages/Contact';
import { useDispatch, useSelector } from 'react-redux';
import { setVerification, selectVerification } from './util/Slice';
import { useEffect } from 'react';
import "./App.scss"
import LinkBar from './components/LinkBar';

const App: React.FC = () => {
  const verifyStyleSelect = useSelector(selectVerification);
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const dispatch = useDispatch();
  const [mode, setMode] = React.useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light');
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );
  const copyright = 
    <Grid item height={"fit-content"} width={"100%"} alignContent={'center'} padding={'8px 0px 8px 0px'}>
      <Typography variant={"body2"} sx={{ fontSize: isSmOrLess ? '11px' : '' }} color={"text.secondary"} align="center">
        {'Copyright © '}
        <Link underline={'hover'} href="http://juliesaraceno.com/">
          Julie Saraceno Therapy
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Grid>;

  const obtainVerifyStyling = () => {
    let verifyButton = document.body.querySelectorAll('[id=psy-today-verify]')[0] as HTMLElement;
    if (verifyButton && !verifyStyleSelect) {
      let css = verifyButton.getAttribute('style');
      if (css) {
        dispatch(setVerification(css));
      } else {
        var observer = new MutationObserver(function(mutations) {
          observer.disconnect(); 
          verifyButton = document.body.querySelectorAll('[id=psy-today-verify]')[0] as HTMLElement;
          const css = verifyButton.getAttribute('style');
          if (css) {
            dispatch(setVerification(css));
          }
        });
        observer.observe(verifyButton, { attributeFilter: ['style'] });
      }
    }
  }
  useEffect(() => {
    obtainVerifyStyling();
    let verifyButton = document.body.querySelectorAll('[id=psy-today-verify]')[0] as HTMLElement;
    if (verifyButton && verifyStyleSelect) {
      verifyButton.style.display = 'none';
    }
  }, [verifyStyleSelect]); 
  useEffect(() => {
    obtainVerifyStyling();
  }, []);
  const updatedTheme = createModeTheme('light'); // createModeTheme(mode);
  function ContentInRouter() {
    const pathName = useLocation().pathname;
    const isHome = pathName === '/home' || pathName === '/';
    return (
      <React.Fragment>
        <Grid container alignContent={"space-between"} height={"100%"} width={"100%"}>
          <Grid item container minHeight={'100%'} height={'fit-content'} paddingBottom={isSmOrLess ? '48px' : 0}>
            <LinkBar home={false} copyright={copyright} mode={theme.palette.mode} toggleColorMode={colorMode.toggleColorMode} />
            <Grid item container minHeight={'100%'} marginTop={'48px'}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<AboutMe />} />
                <Route path="/services" element={<Services />} />
                <Route path="/fees" element={<Fees />} />
                <Route path="/professional" element={<Professional />} />
                <Route path="/contact" element={<Contact />} />
              </Routes>
            </Grid>
          </Grid>
          {copyright}
        </Grid>
        {/* {isSmOrLess && (
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation sx={{ width: '100%', height: 'auto' }} >
              <LinkBar home={false} elevation={0} copyright={copyright} mode={theme.palette.mode} toggleColorMode={colorMode.toggleColorMode} />
            </BottomNavigation>
          </Paper>
        )} */}
      </React.Fragment>
    );
  }
  return ( 
    <ThemeProvider theme={updatedTheme}>
    <CssBaseline />
      <Router>
        <Grid container alignContent={"space-between"} height={"100%"} width={"100%"}>
          <ContentInRouter />
        </Grid>
      </Router>
    </ThemeProvider>
  );
}

export default App;