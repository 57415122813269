import * as React from 'react';
import { Typography, Container, Grid, Link, useMediaQuery, useTheme } from '@mui/material';
import AppointmentButton from '../components/AppointmentButton';
import Verification from '../components/Verification';
import { acceptedInsurance, privatePay } from '../util/Insurance';

const Fees: React.FC = () => {
  const theme = useTheme();
  const isSmOrLess = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      <Container style={{ maxWidth: 560}}>
        <Typography align={'center'} variant={'h4'} sx={{ fontFamily: 'Lora, serif'}} padding={isMdOrLess ? 1 : 3}>
          Insurance and Fees
        </Typography> 
        <Grid container padding={1} spacing={1}>
          <Typography align={'center'} variant={'h5'} sx={{ fontFamily: 'Lora, serif'}}>
            Private Pay Fees
          </Typography>
          <ul>
            {
              privatePay.map((privatePay, index) => {
                return <li key={index}>
                  <Typography align={'left'} variant={'body1'} padding={"4px"}>
                    {privatePay.text}
                    <Typography align={'left'} paddingLeft={1} variant={'body2'}>
                      {privatePay.note}
                    </Typography>
                  </Typography>
                </li>;
              })
            }
          </ul>
        </Grid>
        <Grid container padding={1} paddingBottom={2} spacing={1}>
          <Typography align={'center'} variant={'h5'} sx={{ fontFamily: 'Lora, serif'}}>
            Accepted Insurances
          </Typography>
          <Grid item>
            <ul>
              {
                acceptedInsurance.map((insurance, index) => {
                  return <li key={index}>
                    <Typography align={'left'} variant={'body1'}>
                      {insurance}
                    </Typography>
                  </li>;
                })
              }
            </ul>
            <Typography align={'left'} variant={'body2'}>
              If you are uncertain about your insurance coverage or your copayment/deductible/coinsurance responsibility, please contact your insurance company directly and they will provide you with information specific to your insurance plan.
            </Typography>
            <Typography align={'left'} paddingTop={1} variant={'body2'}>
              *Headway processes claims for these insurances, therefore fees may vary based on their fees and your insurance plan. Feel free to <Link href="https://headway.co/insurance/form">click here</Link> to determine your rate for service.
            </Typography>
          </Grid>
        </Grid>
        <Grid container padding={1}>
          <Typography align={'center'} variant={'h5'} sx={{ fontFamily: 'Lora, serif'}}>
            Out of Network Insurances
          </Typography>
          <Grid item >
            <Typography align={'left'} variant={'body2'} paddingTop={1}>
              I am considered out of network for all other insurances. However, I can provide you with a "Super Bill" for partial reimbursement, if your insurance company offers out-of-network benefits.
            </Typography>
          </Grid>
          {!isSmOrLess && (
            <Grid xs={12} item style={{ paddingTop: 32 }}>
              <AppointmentButton />
            </Grid>
          )}
          <Grid container minWidth={300} item padding={'36px 0px'} justifyContent={'center'}>
            <Grid item padding={1}>
              <Verification />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Fees;
