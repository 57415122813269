import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { selectVerification } from '../util/Slice';
import { useEffect } from 'react';

const Verification: React.FC = () => {
    const buttonRef = useRef<HTMLAnchorElement>(null);
    const verifyStyle = useSelector(selectVerification);
    useEffect(() => {
      if (buttonRef.current && verifyStyle) {
        buttonRef.current.setAttribute('style', verifyStyle);
      }
    }, [buttonRef.current, verifyStyle]);
    return (
      <a ref={buttonRef} href="https://www.psychologytoday.com/profile/974903" className="sx-verified-seal"></a>
    );
};

export default Verification;
